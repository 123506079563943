import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { WalletProvider } from './WalletConnect.js';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <WalletProvider>
        <HashRouter>
          <Switch>
            <Route path={`/admin`} component={AdminLayout} />
            <Redirect from='/' to='/admin/welcome' />
          </Switch>
        </HashRouter>
      </WalletProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);
