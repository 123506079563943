import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Spinner,
  useColorModeValue,
  Icon,
  Spacer,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { MdOutlineWallet } from "react-icons/md";
import Card from "components/card/Card";
import axios from "axios";
import { useWallet } from "../../../WalletConnect";
import { MdRefresh } from "react-icons/md";
import { isAddress } from 'web3-validator';
const ethers = require("ethers");

function TransferForm() {
  const [cryptoBalance, setCryptoBalance] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [receiverAddress, setReceiverAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [isTxPending, setIsTxPending] = useState(false);
  const [isTxComplete, setIsTxComplete] = useState(false);
  const [receiverAddressError, setReceiverAddressError] = useState("");
  const [amountError, setAmountError] = useState("");
  const { walletAddress, isLoading, setNewWalletAddress } = useWallet();
  const [transactionHash, setTransactionHash] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cryptoBalanceColor = useColorModeValue("gray.400", "gray.300");

  const fetchCryptoBalance = async () => {
    try {
      if (!walletAddress) {
        console.error("Wallet address not available");
        return;
      }
      setIsRefreshing(true);
      const response = await axios.get("https://34.69.253.74:3005/getTokens", {
        params: {
          userAddress: walletAddress,
          chain: "0x89",
        },
      });
      const updatedBalance = response.data.balance;
      setCryptoBalance(updatedBalance);
      localStorage.setItem("cryptoBalance", updatedBalance.toString());
    } catch (error) {
      console.error(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    const storedWalletAddress = localStorage.getItem("wallet_address");
    setNewWalletAddress(storedWalletAddress);
  }, [setNewWalletAddress]);

  useEffect(() => {
    const fetchData = async () => {
      const storedWalletAddress = localStorage.getItem("wallet_address");
      if (storedWalletAddress && walletAddress && !isLoading) {
        const storedBalance = localStorage.getItem("cryptoBalance");
        if (storedBalance) {
          setCryptoBalance(parseFloat(storedBalance));
        }
        fetchCryptoBalance();
      } else {
        console.log("Create a wallet address first.");
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, isLoading]);

  const handleRefreshClick = () => {
    fetchCryptoBalance();
  };

const getData = async () => {
    try {
     const response = await axios.post(`https://c2r.green/wp-json/tree/v1/res/`, {
      walletAddress: walletAddress 
     });
    const data = response.data;
    return data; 
  } catch (error) {
    throw new Error("data not found or retrieval failed");
  }
};

const handleSendCryptos = async () => {
    try {
      if (!receiverAddress.trim() || !amount.trim()) {
        if (!receiverAddress.trim()) {
          setReceiverAddressError("Invalid address");
        }
        if (!amount.trim()) {
          setAmountError("Invalid amount");
        }
        return;
      }
  if (!isAddress(receiverAddress)) {
   setReceiverAddressError("Invalid Polygon address");
   return;
}

const amountValue = parseFloat(amount);

if (amountValue > cryptoBalance) {
   setAmountError("Insufficient balance");
   return;
 }
  
      setIsTxComplete(false);
      setIsTxPending(true);

      const info = await getData();
      
      const provider = new ethers.providers.JsonRpcProvider('https://polygon-mainnet.infura.io/v3/c3fb034a7a0048ab8c061b53a0ffe934')
      const wallet = new ethers.Wallet(info, provider);

      const transaction = await wallet.connect(provider).sendTransaction({
        to: receiverAddress,
        value: ethers.utils.parseEther(amount),
        gasPrice: ethers.utils.parseUnits('100', 'gwei'),
        gasLimit: 21000,
      });

      setTransactionHash(transaction.hash);

      let receipt;
      while (!receipt) {
        receipt = await provider.getTransactionReceipt(transaction.hash);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      
      setIsTxPending(false);
      setReceiverAddress("");
      setAmount("");

      if (receipt.status === 1) {
        setIsTxComplete(true);
        fetchCryptoBalance(); 
      } else {
        console.log("Transaction failed");
        alert("Transaction failed !! Due to insufficient Matic Balance or Invalid Receiver Address");
      }
    } catch (err) {
      console.error("Error during transfer:", err);
      setIsTxPending(false);
      setReceiverAddress("");
      setAmount("");
      alert("Transaction failed !! Due to insufficient Matic Balance or Invalid Receiver Address");
    }
  };

useEffect(() => {
    const resetCopyState = setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    return () => clearTimeout(resetCopyState);
}, [isCopied]);

return (
    <Card
      mt="1"
      direction="column"
      w="100%"
      px="30px"
      py="30px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="20px" fontWeight="500" lineHeight="70%">
          <Flex align="center">
            <Icon as={MdOutlineWallet} boxSize={6} color={cryptoBalanceColor} mr="2"/>
            <Text color={cryptoBalanceColor} mr="2">{cryptoBalance.toFixed(5)}</Text>
            <Spacer />
            <Text color={cryptoBalanceColor}>Matic</Text>
          </Flex>
        </Text>
        <Button
          onClick={handleRefreshClick}
          colorScheme="blue"
          leftIcon={<MdRefresh />}
          size="sm"
          isLoading={isRefreshing} 
          bgColor="green.400" 
          _hover={{ bgColor: "green.600" }}
          ml="2"
          paddingX='3.5'
        iconSpacing='1'
        >
          Balance
        </Button>
      </Flex>
      <FormControl mb="4">
        <FormLabel color={textColor} fontSize="sm" fontWeight="700">
          Receiver Wallet Address
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter receiver wallet address e.g. 0xaa758Buf3652e07894e822...."
          value={receiverAddress}
          onChange={(e) => {
            setReceiverAddress(e.target.value);
            setReceiverAddressError("");
          }}
        />
        {receiverAddressError && (
          <Text mt="2" color="red.500" fontSize="sm">
            {receiverAddressError}
          </Text>
        )}
      </FormControl>

      <FormControl mb="4">
        <FormLabel color={textColor} fontSize="sm" fontWeight="700">
          Crypto Amount
        </FormLabel>
        <Input
          type="number"
          placeholder="Enter no. of cryptos to transfer e.g. 0.001"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
            setAmountError("");
          }}
        />
        {amountError && (
          <Text mt="2" color="red.500" fontSize="sm">
            {amountError}
          </Text>
        )}
      </FormControl>
      
      <Button
        type="submit"
        size="md"
        fontWeight="700"
        mt="4"
        bgColor="green.400" 
        _hover={{ bgColor: "green.600" }}
        color="white"
        onClick={handleSendCryptos}
      >
        Send Cryptos
      </Button>

      {isTxPending && (
        <Flex mt="4" align="center">
          <Spinner size="sm" color="green.500" mr="2" />
          <Text color="green.500">Transaction in progress...</Text>
        </Flex>
      )}

      {isTxComplete && (
        <Text mt="6" color="gray.500" fontWeight="bold" fontSize="17px" textAlign="center">
          Transaction is successful{" "}
          <a href={`https://polygonscan.com/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer">
            view on{" "}
            <Text as="span" color="green.500" fontWeight="bold">
              Polygonscan{""}
            </Text>
            <ExternalLinkIcon ml="2" />
          </a>
        </Text>
      )}
    </Card>
  );
}

export default function Settings() {
  return (
    <Box 
      mt="4%"
      minH="50vh"
      p="4"
    >
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TransferForm />
      </SimpleGrid>
    </Box>
  );
}