import {
  Box,
  Text,
  Image,
  Checkbox,
  Link,
  Button,
  Flex,
  Icon,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {FaCopy} from 'react-icons/fa';
import { IoMdWallet } from "react-icons/io";
import { IoAlertCircleSharp } from "react-icons/io5";
import React, { useState, useEffect } from "react";
import { useWallet } from "../../../WalletConnect";

function CreateAccount() {
  const [isChecked, setIsChecked] = useState(false);
  const { walletAddress, setNewWalletAddress } = useWallet();
  const [isCopied, setIsCopied] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isWalletAddressPresent, setIsWalletAddressPresent] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const toast = useToast();

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(walletAddress);
      setIsCopied(true);
      showToast("Copied to clipboard!");
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  const showToast = (message) => {
    toast({
      title: message,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const createWallet = () => {
    if (isChecked) {
      const storedWalletAddress = localStorage.getItem("wallet_address");
      const urlParams = new URLSearchParams(
        window.location.hash.split("?")[1]
      );
      const walletAddressParam =
        urlParams.get("wallet_address") || storedWalletAddress;
      setNewWalletAddress(walletAddressParam);
      localStorage.setItem("wallet_address", walletAddressParam);
      setIsRegistered(true);
      setButtonClicked(true);
    }
  };

  useEffect(() => {
    const storedWalletAddress = localStorage.getItem("wallet_address");
    const initialWalletAddress = storedWalletAddress || walletAddress;
    setNewWalletAddress(initialWalletAddress);
    setIsWalletAddressPresent(
      !!(storedWalletAddress && storedWalletAddress !== "null")
    );
    setIsRegistered(!!(storedWalletAddress && storedWalletAddress !== "null"));
    setButtonClicked(!!(storedWalletAddress && storedWalletAddress !== "null"));
  }, [setNewWalletAddress, walletAddress]);

  useEffect(() => {
    const resetCopyState = setTimeout(() => {
      setIsCopied(false);
    }, 2000);
    return () => clearTimeout(resetCopyState);
  }, [isCopied]);

  if (isWalletAddressPresent) {
    return (
      <Flex
        direction="column"
        align="center"
        p="4"
        bg="white"
        borderRadius="18px"
        mb="2"
        boxShadow="lg"
        maxW={{ base: "100%", md: "50%" }}
      >
        <Text
          fontSize={{ base: "24px", md: "31px" }}
          fontWeight="800"
          textAlign="center"
          mb="2"
          color="#106739"
        >
          Welcome To Your C2R Wallet
        </Text>

        <Image
          src="https://c2r.green/wp-content/uploads/2023/10/ssss.png"
          alt="Dummy Image1"
          borderRadius="md"
          mb="8"
          maxW="30%"
          height="auto"
        />

        <Checkbox
          colorScheme="green"
          mb="4"
          isChecked={isChecked}
          isDisabled
          onChange={() => setIsChecked(!isChecked)}
          iconColor="white"
          borderColor="green.500"
          _focus={{ boxShadow: "none" }}
        >
          <Box>
            I agree to C2R's{" "}
            <Link
              href="https://c2r.green/terms-and-conditions/#"
              color="green.500"
              textDecoration="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and conditions
            </Link>
          </Box>
        </Checkbox>

        <Button
          bgColor="green.400"
          _hover={{ bgColor: "green.600" }}
          color="white"
          variant="solid"
          mb={{ base: "4", md: "5" }}
          mt="5"
          width={{ base: "80%", md: "60%" }}
          fontSize={{ base: "sm", md: "md" }}
          onClick={createWallet}
          isDisabled
        >
          Get C2R wallet Address
        </Button>

        <Flex
          alignItems="center"
          mt="4"
          flexDirection={{ base: "column", md: "row" }}
          textAlign="center"
        >
          <Text
            color="gray.500"
            fontWeight="bold"
            mb={{ base: "0", md: "1" }}
            fontSize={{ base: "sm", md: "md" }}
            display="flex"
            alignItems="center"
          >
  
          <Text as="span" color="gray.500">
            Your wallet address:{"\u00A0"}
        </Text>

          <Tooltip label={walletAddress} hasArrow>
            <Button
              as="span"
              variant="link"
              color="gray.500"
              onClick={() => {}}
            >
              {walletAddress.substring(0, 5)}......{walletAddress.slice(-5)}
            </Button>
            </Tooltip>
             <Text>
            {"\u00A0"}{"\u00A0"}{"\u00A0"}{"\u00A0"}
        </Text>

            <Button
                ml={{ base: "0", md: "1" }}
                 color="white"
                bg="green.400"
                 _hover={{ bg: "green.600" }}
                 onClick={copyToClipboard}
                 cursor="pointer"
                 display="flex"
                 alignItems="center"
                 fontSize="sm"
                 mb="4px"
               >
               {isCopied ? " Copied!" : " Copy"}
             </Button>
            
    </Text>

          <Tooltip label={isCopied ? "Copied!" : "Copy to Clipboard"} hasArrow>
            <Link
              ml={{ base: "0", md: "1" }}
              color="green.500"
              onClick={copyToClipboard}
              cursor="pointer"
            >
            </Link>
          </Tooltip>
        </Flex>

        <Box
          p={4}
          borderWidth="1px"
          borderRadius="md"
          borderColor="red.300"
          bg="red.50"
          mt={4}
        >
          <Flex align="center">
            <Box color="green.500" boxSize="1.5em">
              <IoAlertCircleSharp />
            </Box>
            <Text ml={2} color="green.700" fontWeight="bolder" fontSize="15px">
              Copy the generated C2R wallet address and use it during the checkout process.
            </Text>
          </Flex>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      align="center"
      p="4"
      bg="white"
      borderRadius="18px"
      mb="2"
      boxShadow="lg"
      maxW={{ base: "100%", md: "50%" }}
    >
      <Text
        fontSize={{ base: "24px", md: "31px" }}
        fontWeight="800"
        textAlign="center"
        mb="2"
        color="#106739"
      >
        Welcome To Your C2R Wallet
      </Text>

      <Image
        src="https://c2r.green/wp-content/uploads/2023/10/ssss.png"
        alt="Dummy Image1"
        borderRadius="md"
        mb="8"
        maxW="30%"
        height="auto"
      />

      <Checkbox
        colorScheme="green"
        mb="4"
        isChecked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        iconColor="white"
        borderColor="green.500"
        _focus={{ boxShadow: "none" }}
      >
        <Box>
          I agree to C2R's{" "}
          <Link
            href="https://c2r.green/terms-and-conditions/#"
            color="green.500"
            textDecoration="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and conditions
          </Link>
        </Box>
      </Checkbox>

      <Button
        bgColor="green.400"
        _hover={{ bgColor: "green.600" }}
        color="white"
        variant="solid"
        mb={{ base: "4", md: "5" }}
        mt="5"
        width={{ base: "80%", md: "60%" }}
        fontSize={{ base: "sm", md: "md" }}
        onClick={createWallet}
        isDisabled={isWalletAddressPresent}
      >
        Get C2R wallet Address
      </Button>

      {buttonClicked && (!isRegistered || !isWalletAddressPresent) ? (
        <Flex
          alignItems="center"
          mt="4"
          flexDirection={{ base: "column", md: "row" }}
          textAlign="center"
        >
          {isWalletAddressPresent && (
            <Text
              color="gray.500"
              fontWeight="bold"
              mb={{ base: "0", md: "1" }}
              fontSize={{ base: "sm", md: "md" }}
              display="flex"
              alignItems="center"
            >
              <Icon
                as={IoMdWallet}
                color="gray.500"
                boxSize={{ base: 6, md: 7 }}
                mr="2"
              />
              <Tooltip label={walletAddress} hasArrow>
                <Text as="span" color="gray.500">
                  {walletAddress.substring(0, 5)}......{walletAddress.slice(-5)}
                </Text>
              </Tooltip>
            </Text>
          )}

    {isWalletAddressPresent && (
  <Flex
    alignItems="center"
    mt="4"
    flexDirection={{ base: "column", md: "row" }}
    textAlign="center"
  >
    <Text
      color="gray.500"
      fontWeight="bold"
      mb={{ base: "0", md: "1" }}
      fontSize={{ base: "sm", md: "md" }}
      display="flex"
      alignItems="center"
    >
      <Icon
        as={IoMdWallet}
        color="gray.500"
        boxSize={{ base: 6, md: 7 }}
        mr="2"
      />
      <Tooltip label={walletAddress} hasArrow>
        <Text as="span" color="gray.500">
          {walletAddress.substring(0, 5)}......{walletAddress.slice(-5)}
        </Text>
      </Tooltip>
    </Text>

    <Button
      ml={{ base: '0', md: '1' }}
      colorScheme="green"
      onClick={() => copyToClipboard(walletAddress)}
      cursor="pointer"
      _hover={{ bgColor: 'green.500' }}
      size="lg"
      leftIcon={<FaCopy boxSize={6} />}
    >
      Copy Address
    </Button>
  </Flex>
    )}
        </Flex>
  ) : null}

      {buttonClicked && (!isRegistered || !isWalletAddressPresent) && (
        <Link
          mt="4"
          color="green.500"
          fontWeight="bold"
          fontSize={{ base: "sm", md: "md" }}
          href="https://c2r.green/register/"
          target="_blank"
        >
          Don't have a wallet address yet? Register here at C2R.Green!!
        </Link>
      )}
    </Flex>
  );
}

export default function Overview() {
  return (
    <Box minH="50vh" p={{ base: "4", md: "4" }} mt="3%">
      <CreateAccount />
    </Box>
  );
}
