import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Image,
  Text,
  Button,
  Center,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { useWallet } from "../../../WalletConnect.js";
import { MdRefresh } from "react-icons/md";
import { useLocation } from "react-router-dom";

export default function Marketplace() {
  const [nftData, setNftData] = useState([]);
  const [loadingNfts, setLoadingNfts] = useState(false);
  const { walletAddress, isLoading } = useWallet();
  const [noNftsFound, setNoNftsFound] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const location = useLocation();

  const fetchNFTs = async (addressToFetch) => {
    try {
      setLoadingNfts(true);

      const response = await axios.get("https://34.69.253.74:3005/getTokens", {
        params: {
          userAddress: addressToFetch,
          chain: "0x89",
        },
      });

      const { nfts } = response.data;

      const filteredNFTs = nfts.filter(nft =>
        [
          "0x43681920e1068480208568e0c93f98ebea2c4e14",
          "0xfcc9547f59a188b970c953e1cd758c077170fac2",
          "0x6f18381fb633450b144eca9136c237191c3205f4",
          "0x5fe7ed1cf8e404ee5cda494229e83d52b55abb85"
        ].includes(nft.token_address)
      );

      setNoNftsFound(filteredNFTs.length === 0);
      setNftData(filteredNFTs);

    } catch (error) {
      console.error("Error fetching NFTs:", error);
    } finally {
      setLoadingNfts(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (walletAddress && !isLoading) {
        const isMynftsPage = location.pathname.startsWith("/admin/mynfts");
        if (isMynftsPage) {
          await fetchNFTs(walletAddress);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, isLoading, location.pathname]);

  const handleRefreshClick = async () => {
    try {
      setIsRefreshing(true);
      let generatedWalletAddress = localStorage.getItem("wallet_address");
      if (!generatedWalletAddress) {
        generatedWalletAddress = walletAddress;
      }
      await fetchNFTs(generatedWalletAddress);
    } finally {
      setIsRefreshing(false);
    }
  };

  const getNFTDetailsLink = (tokenOwner) => {
    return `https://polygonscan.com/address/${tokenOwner}#nfttransfers`;
  };

  return (
    <Box pt={{ base: "1px", md: "8px", xl: "80px" }}>
      <Flex
        direction="column"
        align="center"
        p="12"
        bg="white"
        borderRadius="18px"
        boxShadow="lg"
        mb="4"
      >
        <Text
          fontSize={{ base: "24px", md: "31px" }}
          fontWeight="800"
          textAlign="center"
          mb="5"
          color="#106739"
        >
          Your NFTs
        </Text>
        <Flex align="center" mb="2" flexDirection={{ base: "column", md: "row" }}>
          {/* Additional components can be added here if needed */}
        </Flex>
        <Flex justifyContent="center" mb="4">
          <Button
            onClick={handleRefreshClick}
            isLoading={isRefreshing}
            loadingText="Refreshing"
            leftIcon={<MdRefresh />}
            position="relative"
            colorScheme="green"
            _hover={{ bg: "green.500" }}
            mb="20px"
          >
            NFTs
          </Button>
        </Flex>
        {loadingNfts && <Center><Spinner size="lg" color="teal.500" /></Center>}
        {noNftsFound && !loadingNfts && (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Box textAlign="center" color="gray.500" fontSize="lg">
              No NFTs found for this address.
            </Box>
          </Flex>
        )}
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          gap="20px"
          mb={{ base: "20px", xl: "0px" }}
        >
          {nftData.map((nft) => (
            <Box
              key={nft.id}
              borderRadius="xl"
              overflow="hidden"
              boxShadow="md"
              borderColor="green.400"
              borderWidth="2px"
            >
              <Flex
                align="center"
                justify="center"
                height="300px"
                overflow="hidden"
              >
                <Image
                  src={nft.image}
                  alt={nft.name}
                  maxH="100%"
                  maxW="100%"
                  objectFit="cover"
                />
              </Flex>
              <Box p="6" bgColor="#EBEBEB">
                <Center flexDirection="column"> {/* Setting flexDirection to column */}
                  <Text fontSize="xl" fontWeight="semibold">
                    {nft.name}
                  </Text>
                  <Text fontSize="18px" fontWeight="semibold" color="gray.400" mt="2"> {/* Adjusting margin top */}
                    Total Quantity: {' '}
                    <Text as="span" fontSize="16px" fontWeight="bold" fontFamily="inherit">{nft.token_amount}</Text> {/* Adjusting font size */}
                  </Text>
                </Center>
                <Center>
                  <Button
                    mt={4}
                    bg="green.400"
                    _hover={{ bg: "green.500" }}
                    color="white"
                    onClick={() =>
                      window.open(getNFTDetailsLink(walletAddress), "_blank")
                    }
                  >
                    View NFT Details
                  </Button>
                </Center>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
}
