import React from "react";
import { Icon } from "@chakra-ui/react";
import { IoMdWallet } from "react-icons/io";
import { RiNftFill } from "react-icons/ri";
import { MdOutlineCurrencyBitcoin } from "react-icons/md";
import { FaCoins } from "react-icons/fa";
import Mynfts from "views/admin/mynfts";
import MyCryptos from "views/admin/mycryptos";
import MyTokens from "views/admin/mytokens";
import Welcome from "views/admin/welcome"

const routes = [
    {
    name: "My Wallet",
    layout: "/admin",
    path: "/welcome",
    icon: <Icon as={IoMdWallet} width='20px' height='20px' color='green.400' />,
    component: Welcome,
    defaultActive: true,
  },
  {
    name: "My NFTs",
    layout: "/admin",
    path: "/mynfts",
    icon: <Icon as={RiNftFill} width='20px' height='20px' color='green.400'/>,
    component: Mynfts,
  },
  {
    name: "My Carbon Credits",
    layout: "/admin",
    icon: <Icon as={FaCoins} width='20px' height='20px' color='green.400' />,
    path: "/mytokens",
    component: MyTokens,
  },
  {
    name: "My Cryptos",
    layout: "/admin",
    icon: <Icon as={MdOutlineCurrencyBitcoin} width='20px' height='20px' color='green.400' />,
    path: "/mycryptos",
    component: MyCryptos,
  },
];

export default routes;
