// import React from "react";

// // Chakra imports
// import { Flex } from "@chakra-ui/react";

// // Custom components
// // import { HorizonLogo } from "components/icons/Icons";

// export function SidebarBrand() {

//   return (
//     <Flex align='center' direction='column'>
//     </Flex>
//   );
// }

// export default SidebarBrand;

import React from "react";

// Chakra imports
import { Flex } from "@chakra-ui/react";

// Custom components
// import { GreenLogo } from "components/icons/Icons";

export function SidebarBrand() {
  //   Chakra color mode
  // let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column' mr='1px'>
      {/* <GreenLogo h='200px' w='200px' my='32px' color={logoColor} /> */}
    </Flex>
  );
}

export default SidebarBrand;