import React from "react";
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  Slide,
  IconButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import { renderThumb, renderTrack, renderView } from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";

export function SidebarResponsive(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleToggle = () => {
    isOpen ? onClose() : onOpen();
  };

  const sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  const menuColor = useColorModeValue("gray.400", "white");

  return (
    <Box position="relative">
      {/* Desktop Sidebar */}
      <Flex
        display={{ sm: "none", xl: "flex" }}
        flexDirection="column"
        alignItems="center"
        position="fixed"
        top="0"
        zIndex="2"
        h="100vh" // Set the height to 100% of the viewport height
        w={{ base: "100%", xl: "300px" }}
        bg={sidebarBackgroundColor}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Content {...props} />
        <Box w="100%" borderTop="1px solid" borderColor="gray.200" mt="auto" />
      </Flex>

      {/* Mobile Sidebar */}
      <Flex
        display={{ sm: "flex", xl: "none" }}
        alignItems="center"
        position="fixed"
        top="0"
        zIndex="2"
        w="100%"
        px="2"
        py="2"
        bg={sidebarBackgroundColor}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <IconButton
          icon={isOpen ? <IoCloseOutline /> : <IoMenuOutline />}
          onClick={handleToggle}
          color={menuColor}
          size="lg"
          mr="4"
        />
      </Flex>

      {/* Slide-in Sidebar for smaller screens */}
      <Slide direction="left" in={isOpen} style={{ zIndex: 1 }}>
        <Drawer
          placement="left"
          onClose={onClose}
          isOpen={isOpen}
          finalFocusRef={null}
          size="xs"
        >
          <DrawerOverlay />
          <DrawerContent bg={sidebarBackgroundColor} p="4">
            <DrawerCloseButton />
            <DrawerBody maxW="100%" px="0" pb="0">
              <Scrollbars
                autoHide
                renderTrackVertical={renderTrack}
                renderThumbVertical={renderThumb}
                renderView={renderView}
              >
                <Content {...props} />
              </Scrollbars>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Slide>
    </Box>
  );
}

SidebarResponsive.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default SidebarResponsive;
