// chakra imports
import { Box, Flex, Stack, Link, Text } from "@chakra-ui/react";
// Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import { HiExternalLink } from "react-icons/hi";
import React from "react";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;

  // Exclude specific routes ("Create Wallet" and "Recover") from being displayed in the UI
  const excludedRoutes = ["Recover", "My Wallet Address"];
  const filteredRoutes = routes.filter(route => !excludedRoutes.includes(route.name));

  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={filteredRoutes} />
        </Box>
      </Stack>
        <Link mt='10px' mb='860px' ml='30px' href="http://c2r.green" isExternal display="flex" alignItems="center" >
          <HiExternalLink width="30px" height="30px" style={{ color: '#48BB78', important : "true"}}/>
          <Text ml="4" color="gray.400" fontSize="17px">Return To C2R.green</Text>
        </Link>
    </Flex>
  );
}

export default SidebarContent;
